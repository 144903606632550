import React from 'react';
import ProductCard from '~/components/ProductCard';

const ProductsBlock = (props) => {
    const { title, products } = props;

    return (
        <div>
            {title ? <div className="row-title">{title}</div> : null}
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-x-10">
                {products.map((product, i) =>
                    i < 4 ? (
                        <ProductCard
                            key={product.sku}
                            product={product}
                            showAddToCartButton={false}
                        />
                    ) : null
                )}
            </div>
        </div>
    );
};

export default ProductsBlock;
