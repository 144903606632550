import Link from 'next/link';
import Button from '../Button';
import { NextSeo } from 'next-seo';

const NoProducts = () => {
    return (
        <div className="w-full content-container py-8">
            <NextSeo title="Es befinden sich leider keine Artikel in dieser Kategorie. | Funkelhaus.de" />
            <h1 className="text-center">
                {"Hoppla, hier geht's leider nicht weiter."}
            </h1>
            <p className="text-center">
                Es befinden sich leider keine Artikel in dieser Kategorie.
            </p>
            <Link passHref href="/">
                <Button type="submit" className="mx-auto">
                    Zur Startseite
                </Button>
            </Link>
        </div>
    );
};

export default NoProducts;
