import React, { useState, useMemo } from 'react';
import EnergyClassTwoA from './images/lamps_energy_labels/Leuchte_Energieklassen.jpg';
import EnergyClassTwoB from './images/lamps_energy_labels/Leuchte_Energieklassen2.jpg';
import EnergyClassTwoC from './images/lamps_energy_labels/Leuchte_Energieklassen3.jpg';
import EnergyClassTwoD from './images/lamps_energy_labels/Leuchte_Energieklassen4.jpg';
import EnergyClassTwoE from './images/lamps_energy_labels/Leuchte_Energieklassen5.jpg';
import EnergyClassTwoF from './images/lamps_energy_labels/Leuchte_Energieklassen6.jpg';
import EnergyClassTwoG from './images/lamps_energy_labels/Leuchte_Energieklassen7.jpg';
import { checkUrlExists } from '../../util/checkUrlExists.js';
import Image from 'next/image';
import Link from 'next/link';
import PopupBox from '../PopupBox';

const RenderLampEnergyLabel = (props: {
    energyClassProp?: number;
    sku?: string;
}) => {
    const { energyClassProp, sku } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const checkImageExist = useMemo(() => {
        return checkUrlExists('/pub/media/energylabel/' + sku + '_el.jpg');
    }, [sku]);

    const checkPdfExist = useMemo(() => {
        return checkUrlExists('/pub/media/productsheet/' + sku + '_pb.pdf');
    }, [sku]);

    if (energyClassProp) {
        const energyClassTwoA = (
            <Image
                src={EnergyClassTwoA}
                alt="Energieklasse Leuchtmittel"
                width="76"
                height="38"
            />
        );
        const energyClassTwoB = (
            <Image
                src={EnergyClassTwoB}
                alt="Energieklasse Leuchtmittel"
                width="76"
                height="38"
            />
        );
        const energyClassTwoC = (
            <Image
                src={EnergyClassTwoC}
                alt="Energieklasse Leuchtmittel"
                width="76"
                height="38"
            />
        );
        const energyClassTwoD = (
            <Image
                src={EnergyClassTwoD}
                alt="Energieklasse Leuchtmittel"
                width="76"
                height="38"
            />
        );
        const energyClassTwoE = (
            <Image
                src={EnergyClassTwoE}
                alt="Energieklasse Leuchtmittel"
                width="76"
                height="38"
            />
        );
        const energyClassTwoF = (
            <Image
                src={EnergyClassTwoF}
                alt="Energieklasse Leuchtmittel"
                width="76"
                height="38"
            />
        );
        const energyClassTwoG = (
            <Image
                src={EnergyClassTwoG}
                alt="Energieklasse Leuchtmittel"
                width="76"
                height="38"
            />
        );

        let energyClass = null;

        switch (energyClassProp) {
            case 30092:
                energyClass = energyClassTwoA;
                break;
            case 30093:
                energyClass = energyClassTwoB;
                break;
            case 30094:
                energyClass = energyClassTwoC;
                break;
            case 30095:
                energyClass = energyClassTwoD;
                break;
            case 30096:
                energyClass = energyClassTwoE;
                break;
            case 30097:
                energyClass = energyClassTwoF;
                break;
            case 1196684:
                energyClass = energyClassTwoG;
                break;

            default:
                break;
        }

        return (
            <div role="presentation">
                <div
                    className={`mb-4 xs:flex xs:flex-row justify-start items-center`}
                >
                    {checkImageExist ? (
                        <button
                            type="button"
                            onClick={() => setOpenDialog(true)}
                            className="flex justify-start items-center"
                        >
                            {energyClass}
                        </button>
                    ) : (
                        <div
                            role="presentation"
                            className="flex justify-start items-center"
                        >
                            {energyClass}
                        </div>
                    )}
                    <span>
                        {checkPdfExist ? (
                            <Link
                                href={
                                    '/pub/media/productsheet/' + sku + '_pb.pdf'
                                }
                                passHref
                            >
                                <a
                                    target="_blank"
                                    className="underline text-sm text-skin-muted ml-2"
                                >
                                    Datenblatt
                                </a>
                            </Link>
                        ) : null}
                    </span>
                </div>
                <PopupBox
                    headline={``}
                    isBoxOpened={openDialog}
                    setIsBoxOpened={setOpenDialog}
                >
                    <Image
                        src={'/pub/media/energylabel/' + sku + '_el.jpg'}
                        alt=""
                        width="207"
                        height="426"
                    />
                </PopupBox>
            </div>
        );
    } else {
        return <span />;
    }
};
export default RenderLampEnergyLabel;
