import React from 'react';
import Link from 'next/link';
import { NextSeo } from 'next-seo';

const ErrorPage = () => {
    return (
        <div className="w-full content-container py-8">
            <NextSeo title="Seite nicht gefunden" />
            <h1 className="text-center">
                {"Hoppla, hier geht's leider nicht weiter."}
            </h1>
            <p className="text-center">
                Gehen Sie zurück zu unserer Startseite und entdecken Sie unser
                aktuelles Sortiment sowie tolle Angebote und Aktionen.
            </p>
            <Link passHref href="/">
                <button
                    type="submit"
                    className="flex justify-center mx-auto py-2 px-4 bg-skin-blue-bg text-sm font-bold text-white rounded-md transition-all duration-500 ease-in-out hover:bg-skin-primary-hover"
                >
                    Zur Startseite
                </button>
            </Link>
        </div>
    );
};

export default ErrorPage;
