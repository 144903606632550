import React from 'react';
import { useQuery } from '@apollo/client';
import getGeneratedAttributes from '~/queries/getGeneratedAttributes.graphql';
import InfoIcon from './icons/info.svg';
import MeasureIcon from './icons/measure.svg';
import ColorIcon from './icons/color.svg';
import DimmbarIcon from './icons/dimmbar.svg';
import LightColor from './icons/lightcolor.svg';
import Lamp from './icons/lamp.svg';
import Bulb from './icons/bulb.svg';
import LightFlux from './icons/lightflux.svg';
import Garantie from './icons/garantie.svg';
// import Dimmerart from './icons/dimmerart.svg';
import Smarthome from './icons/smarthome.svg';
import Protection from './icons/protection.svg';
import Switchingcycles from './icons/switchingcycles.svg';
import Leuchtmittel from './icons/leuchtmittel.svg';
import Hightlights from './icons/highlights.svg';
import Werbung from './icons/werbung.svg';

const GeneratedAttributes = (props) => {
    const { product } = props;

    const excludedBlocks = ['Energieklassen', 'Werbung'];

    const { data, loading, error } = useQuery(getGeneratedAttributes, {
        variables: {
            sku: product.sku
        },
        fetchPolicy: 'cache-and-network'
    });

    if (error) {
        if (process.env.NODE_ENV !== 'production') {
            console.error(error);
        }
        return '';
    }
    if (loading) return <div className="loadingIndicatorContainer"></div>;

    const attributes = JSON.parse(data.generatedAttributes.attributes);

    const clearInfoInBrackets = (value) => {
        return value.replace(/ \([\s\S]*?\)/g, '');
    };

    const renderValue = (value, type, code) => {
        if (type == 'price' || type == 'weight') {
            if (Number.isInteger(parseFloat(value))) {
                return parseInt(value);
            } else {
                return parseFloat(value, 2);
            }
        } else {
            return code.startsWith('txt2_') ? (
                <span
                    dangerouslySetInnerHTML={{
                        __html: clearInfoInBrackets(value)
                    }}
                />
            ) : (
                <span dangerouslySetInnerHTML={{ __html: value }} />
            );
        }
    };

    const renderIcon = (label) => {
        switch (label) {
            case 'Artikelinformationen':
                return (
                    <InfoIcon
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            case 'Abmessungen':
                return (
                    <MeasureIcon
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            case 'Material und Farbe':
                return (
                    <ColorIcon
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            case 'Dimmbar':
                return (
                    <DimmbarIcon
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            case 'Lichtfarbe':
                return (
                    <LightColor
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            case 'Inklusive':
                return (
                    <Lamp
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            case 'Wechselbar':
                return (
                    <Bulb
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            case 'Helligkeit':
                return (
                    <LightFlux
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            case 'Sicherheit':
                return (
                    <Protection
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            case 'Smarthome':
                return (
                    <Smarthome
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            case 'Leuchtmittel':
                return (
                    <Leuchtmittel
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            case 'Sonstige Highlights':
                return (
                    <Hightlights
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            case 'Werbung':
                return (
                    <Werbung
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            case 'Lebensdauer':
                return (
                    <Switchingcycles
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            case 'Garantie':
                return (
                    <Garantie
                        className={
                            'min-w-[50px] w-9 h-9 lg:w-[50px] lg:h-[50px] mt-1'
                        }
                    />
                );
            default:
                return null;
        }
    };

    const labelsToExclude = [
        'dimmable_explenation',
        'dimm_type',
        'dimmertype_explenation',
        'dimmable_explenation',
        'dimmable_text',
        'dimmertype_explenation',
        'dimm_type',
        'lightcolor_explenation',
        'garantie_lamps',
        'protection_main',
        'mean_life',
        'smarthome_explanation',
        'smarthome_main',
        'rgb_explanation',
        'light_flux'
    ];

    const attributesToHide = ['lightcolor_filter', 'filtercolor'];

    return (
        <>
            {product.description && product.description.html ? (
                <div className="col-span-full">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: product.description.html
                        }}
                    />
                </div>
            ) : null}
            {attributes.map((group, key) =>
                !excludedBlocks.includes(group.label) ? (
                    <div key={group.id} className="flex items-start">
                        {renderIcon(group.label)}
                        <div className="pl-2 md:pl-5">
                            <strong className="block text-lg lg:text-xl">
                                {group.label == 'Wechselbar' ||
                                group.label == 'Dimmbar' ||
                                group.label == 'Inklusive' ||
                                group.label == 'Lichtfarbe'
                                    ? group.attributes[0].value
                                    : group.label}
                            </strong>
                            <ul className="attributesList-bak">
                                {group.attributes
                                    .filter(
                                        (f) =>
                                            !attributesToHide.includes(f.code)
                                    )
                                    .map((attribute, index) => {
                                        if (
                                            group.label == 'Wechselbar' ||
                                            group.label == 'Dimmbar' ||
                                            group.label == 'Inklusive' ||
                                            group.label == 'Lichtfarbe'
                                        ) {
                                            if (index > 0) {
                                                return (
                                                    <li
                                                        key={Math.random()}
                                                        className="whitespace-normal relative pl-4"
                                                    >
                                                        <span className="text-base flex items-center before:block before:absolute before:left-0 before:top-1.5 before:w-3 before:h-3 before:rounded-full before:bg-skin-gray-bg flex-wrap">
                                                            {labelsToExclude.includes(
                                                                attribute.code
                                                            ) ? null : (
                                                                <span className="mr-2">
                                                                    {attribute.label +
                                                                        ':'}
                                                                </span>
                                                            )}
                                                            {attribute.value ===
                                                                'No' ||
                                                            attribute.value ===
                                                                'Yes'
                                                                ? renderValue(
                                                                      attribute.value
                                                                          .replace(
                                                                              'No',
                                                                              'Nein'
                                                                          )
                                                                          .replace(
                                                                              'Yes',
                                                                              'Ja'
                                                                          ),
                                                                      attribute.type,
                                                                      attribute.code
                                                                  )
                                                                : renderValue(
                                                                      attribute.value,
                                                                      attribute.type,
                                                                      attribute.code
                                                                  )}
                                                        </span>
                                                    </li>
                                                );
                                            }
                                        } else {
                                            return (
                                                <li
                                                    key={Math.random()}
                                                    className="whitespace-normal relative pl-4"
                                                >
                                                    <span className="text-base flex items-center before:block before:absolute before:left-0 before:top-1.5 before:w-3 before:h-3 before:rounded-full before:bg-skin-gray-bg flex-wrap">
                                                        {labelsToExclude.includes(
                                                            attribute.code
                                                        ) ? null : (
                                                            <span className="mr-2">
                                                                {attribute.label +
                                                                    ':'}
                                                            </span>
                                                        )}
                                                        {attribute.value ===
                                                            'No' ||
                                                        attribute.value ===
                                                            'Yes'
                                                            ? renderValue(
                                                                  attribute.value
                                                                      .replace(
                                                                          'No',
                                                                          'Nein'
                                                                      )
                                                                      .replace(
                                                                          'Yes',
                                                                          'Ja'
                                                                      ),
                                                                  attribute.type,
                                                                  attribute.code
                                                              )
                                                            : renderValue(
                                                                  attribute.value,
                                                                  attribute.type,
                                                                  attribute.code
                                                              )}
                                                    </span>
                                                </li>
                                            );
                                        }
                                    })}
                            </ul>
                        </div>
                    </div>
                ) : null
            )}
        </>
    );
};

export default GeneratedAttributes;
