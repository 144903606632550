import React from 'react';
import { object } from 'prop-types';
import RenderSerie from './renderSerie';
import RenderSerieColors from './renderSerieColors';

const ProductSeries = props => {
    const { data, parentSku } = props;

    return (
        <div>
            {data?.related_flames ? (
                <RenderSerie
                    products={data?.related_flames}
                    name="Flammenanzahl"
                    parentSku={parentSku}
                    type={"flames"}
                />
            ) : null}
            {data?.related_others ? (
                <RenderSerie
                    products={data?.related_others}
                    name="Weitere Artikel"
                    type={"others"}
                    parentSku={parentSku}
                />
            ) : null}
            {data?.related_size ? (
                <RenderSerie products={data?.related_size} name="Größe" type={"size"} parentSku={parentSku} />
            ) : null}
            {data?.related_color?.split(",")?.length > 0 ? (
                <RenderSerieColors data={data} parentSku={parentSku} />
            ) : null}
        </div>
    );
};

export default ProductSeries;

ProductSeries.propTypes = {
    data: object
};
