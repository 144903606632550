import React, { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import Link from 'next/link';
import { RenderColorOrIcon } from '../FilterSidebar/filtersRelatedFunctions';
import axios from 'axios';
import qs from 'qs';

const RenderSerieColor = (props) => {
    const { productSku, parentSku } = props;
    const [colors, setColors] = useState([]);
    colors.filter((f) => f.sku == productSku).length == 0
        ? axios
              .get('/findlogic-colours', {
                  params: {
                      query: productSku,
                      shopkey: '8E36BC284E2AFC9493004E193DA425DA',
                      outputAdapter: 'JSON_1.0',
                      properties: ['filtercolor_fkh', 'sku'],
                      count: 30,
                      outputAttrib: ['filtercolor_fkh']
                  },
                  paramsSerializer: qs.stringify
              })
              .then((response) => {
                  const fetchedColor =
                      response?.data?.result?.filters?.main?.filter(
                          (f) => f.name == 'filtercolor_fkh'
                      )[0]?.values[0];

                  //Prepare images
                  const fetchedImage =
                      response?.data?.result?.items?.length > 0
                          ? response?.data?.result?.items
                          : null;

                  const productUrl = response?.data?.result?.items[0]?.url;
                  setColors([
                      ...colors,
                      {
                          color: fetchedImage,
                          sku: productSku,
                          productUrl
                      }
                  ]);
              })
              .catch((e) => {
                  console.log(e);
              })
        : null;
    return colors ? (
        colors.length > 0 ? (
            <Disclosure.Panel className={'pt-2'}>
                <div className={'flex justify-start items-center'}>
                    {colors.length > 0
                        ? colors.map((color, index) => {
                              return color?.sku.trim() == parentSku.trim() ? (
                                  <span key={index}>
                                      {color?.color &&
                                      color?.color[0]?.imageUrl ? (
                                          <RenderColorOrIcon
                                              option={color?.color[0]?.imageUrl}
                                              productPageMode={true}
                                              isImage={true}
                                              isActive={true}
                                          />
                                      ) : null}
                                  </span>
                              ) : color?.color && color?.color[0]?.imageUrl ? (
                                  <Link
                                      key={index}
                                      href={
                                          color?.productUrl
                                              ? new URL(color.productUrl)
                                                    ?.pathname
                                              : null
                                      }
                                      passHref
                                  >
                                      <a>
                                          <RenderColorOrIcon
                                              option={color?.color[0]?.imageUrl}
                                              productPageMode={true}
                                              isImage={true}
                                          />
                                      </a>
                                  </Link>
                              ) : null;
                          })
                        : null}
                </div>
            </Disclosure.Panel>
        ) : null
    ) : null;
};

export default RenderSerieColor;
