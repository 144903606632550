/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React from 'react';
import CategoryContent from './CategoryContentFindlogic';
import { useQuery } from '@apollo/client';
import APP_QUERY from '~/queries/App.graphql';
import { NextSeo } from 'next-seo';

export const Category = (props) => {
    const { apiData } = props;
    const { categoryFilters, sortFields, categoryProducts, category } = apiData;
    //console.log('Category API data: ', apiData);

    // Get App data and navigation categories
    const { data } = useQuery(APP_QUERY);
    const storeConfig = data?.storeConfig;
    const categoryUrlSuffix = '';
    const productUrlSuffix = storeConfig?.product_url_suffix ?? '';

    if (!category) {
        return (
            <div className="flex w-full h-full items-center min-h-10 p-20">
                <p className="text-center w-full text-skin-muted">
                    Beim Abrufen der Kategorie ist ein Fehler aufgetreten. Bitte
                    laden Sie die Seite erneut.
                </p>
            </div>
        );
    }

    const handledCategoryData = {
        products: categoryProducts.data.products
    };

    return (
        <React.Fragment>
            <NextSeo
                description={category.meta_description}
                title={category.category_seo_name}
            />

            {category ? (
                <CategoryContent
                    categoryId={category.id}
                    category={category}
                    categoryData={handledCategoryData}
                    pageSize={1}
                    categoryUrlSuffix={categoryUrlSuffix}
                    productUrlSuffix={productUrlSuffix}
                    categoryFilters={categoryFilters}
                    sortFields={sortFields}
                    categoryLoading={categoryProducts.loading}
                />
            ) : null}
        </React.Fragment>
    );
};
