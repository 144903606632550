import { useState, useEffect } from 'react';
import { useFindlogic } from '../../../talons/Findlogic/useFindlogic';
import ProductCardFindologic from '../../ProductCard/ProductCardFindologic';
import shuffleArray from '../../../util/shuffleArray';
import Slider from 'react-slick';

const SameCategoryProducts = ({
    productCategories,
    productSku,
    parentCategory
}: {
    productCategories: [{ id: number; name: string; url_path: string }];
    productSku: string;
    parentCategory?: any;
}) => {
    const [loading, setLoading] = useState(false);
    const [counter, setCounter] = useState(0);
    const [initLoading, setInitLoading] = useState(true);
    const [sortLoading, setSortLoading] = useState(false);
    const [results, setResults] = useState<{
        result: {
            items: [];
        };
    }>();
    const { getProductsByCategory } = useFindlogic({
        setLoading,
        setInitLoading,
        setSortLoading
    });

    //We take parent category from product categories
    const productCategoriesSorted: { url_path: string; name: string }[] = [
        ...productCategories?.sort((a: any, b: any) => b['level'] - a['level'])
    ];
    ////////////////////////////////////////////////////////////////
    const disablePreparedCat =
        productCategoriesSorted.filter((f) =>
            parentCategory
                ? parentCategory.category_url_path?.includes(f.url_path)
                : null
        ).length == 0
            ? true
            : false;

    //we handle parent category amd prepare it for Findlogic
    const parentCategoryInfo = productCategoriesSorted?.filter(
        (f) => f.url_path && f.url_path == parentCategory?.category_url_path
    );
    const parentCategoryName =
        parentCategoryInfo.length > 0 ? parentCategoryInfo[0]?.name : null;
    const handledParentCategory =
        parentCategoryName &&
        parentCategoryName.length > 0 &&
        !disablePreparedCat
            ? parentCategoryName
            : Array.isArray(productCategoriesSorted) &&
              productCategoriesSorted.length > 0 &&
              productCategoriesSorted[counter]
            ? productCategoriesSorted[counter]?.name
            : null;
    ////////////////////////////////////////////////////////////////

    useEffect(() => {
        results && results?.result?.items?.length == 0
            ? setCounter(counter + 1)
            : null;
    }, [results]);

    useEffect(() => {
        handledParentCategory
            ? getProductsByCategory(setResults, handledParentCategory, 1)
            : null;
    }, [handledParentCategory]);

    const settings = {
        dots: false,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        infinite: false,
        className: 'topArrowsStyle topsellerCarousel',
        draggable: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    arrows: true,
                    infinite: true,
                    centerMode: false,
                    speed: 500,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    className: 'topsellerCarousel topArrowsStyle',
                    draggble: true
                }
            },
            {
                breakpoint: 640,
                settings: {
                    dots: false,
                    arrows: true,
                    infinite: true,
                    centerMode: true,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    className: 'topsellerCarousel topArrowsStyle',
                    draggble: true
                }
            },
            {
                breakpoint: 520,
                settings: {
                    dots: false,
                    arrows: true,
                    infinite: true,
                    centerMode: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    className: 'topsellerCarousel topArrowsStyle',
                    draggble: true
                }
            }
        ]
    };

    const products = results ? results?.result?.items : null;

    const filteredProducts: any = products?.filter(
        (f: { ordernumbers: [string] }) =>
            f.ordernumbers &&
            Array.isArray(f.ordernumbers) &&
            !f.ordernumbers.includes(productSku)
    );

    const handledProducts: [] = filteredProducts ? filteredProducts : undefined;

    if (loading || initLoading || sortLoading) {
        return <span />;
    }

    return handledParentCategory &&
        handledProducts &&
        Array.isArray(handledProducts) &&
        handledProducts.length > 0 ? (
        <div className="mt-12">
            <h2>Dies könnte Ihnen auch gefallen</h2>
            <Slider {...settings}>
                {shuffleArray(handledProducts)
                    .slice(0, 10)
                    .map((item: { id: number }) => (
                        <div
                            key={item.id}
                            className={
                                'px-4 w-2/3 sm:w-1/2 block flex-[0_0_auto] product-block-container'
                            }
                        >
                            <ProductCardFindologic
                                key={Math.random()}
                                product={item}
                                disableScroll={true}
                            />
                        </div>
                    ))}
            </Slider>
        </div>
    ) : null;
};

export default SameCategoryProducts;
