import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import GET_PRODUCTS_BY_SKU from '../../queries/getProductsBySku.graphql';
import { Disclosure } from '@headlessui/react';
import Link from 'next/link';
import { variantsLoadingTemplate } from '../LoadingContent/loadingTemplates';
import { useRouter } from 'next/router';

interface RenderSerieProps {
    name?: string;
    parentSku?: string;
    type?: string;
    products: string;
}

const RenderSerie = (props: RenderSerieProps) => {
    const { products, name, parentSku, type } = props;
    const productsArr = products.split(',');
    const { data, error, loading } = useQuery(GET_PRODUCTS_BY_SKU, {
        variables: { products: productsArr }
    });
    const router = useRouter();
    const path = router?.asPath;

    const [moreSizes, setMoreSize] = useState<boolean>(false);
    const [moreOthers, setMoreOthers] = useState<boolean>(false);
    const [moreFlames, setMoreFlames] = useState<boolean>(false);

    const resetShowMoreVariants = () => {
        moreSizes ? setMoreSize(false) : null;
        moreOthers ? setMoreOthers(false) : null;
        moreFlames ? setMoreFlames(false) : null;
    };

    useEffect(() => {
        resetShowMoreVariants();
    }, [path]);

    const items =
        data?.products?.items.length > 0 ? [...data.products.items] : [];

    const RenderDescription = (props: {
        attrName?: string;
        product: {
            related_flames_description?: string;
            related_others_description?: string;
            related_size_description?: string;
        };
    }) => {
        const { attrName, product } = props;

        switch (attrName) {
            case 'Flammenanzahl':
                return product?.related_flames_description ? (
                    <span>{product.related_flames_description}</span>
                ) : null;
            case 'Weitere Artikel':
                return product?.related_others_description ? (
                    <span>{product.related_others_description}</span>
                ) : null;
            case 'Größe':
                return product?.related_size_description ? (
                    <span>{product.related_size_description}</span>
                ) : null;

            default:
                return <span />;
                break;
        }
    };

    const setShowMoreVariants = (value: boolean, type: string) => {
        switch (type) {
            case 'size':
                setMoreSize(value);
                break;
            case 'flames':
                setMoreFlames(value);
                break;
            case 'others':
                setMoreOthers(value);
                break;

            default:
                break;
        }
    };

    if (loading) return <div>{variantsLoadingTemplate}</div>;

    if (error) return <span />;

    const productSizesArr: {
        sku: string;
        url_key: string;
        related_flames_description?: string;
        related_others_description?: string;
        related_size_description?: string;
    }[] = [];
    const productFlamesArr: {
        sku: string;
        url_key: string;
        related_flames_description?: string;
        related_others_description?: string;
        related_size_description?: string;
    }[] = [];

    const productOthersArr: {
        sku: string;
        url_key: string;
        related_flames_description?: string;
        related_others_description?: string;
        related_size_description?: string;
    }[] = [];

    if (type == 'size') {
        productsArr.map((productSku) => {
            const product = items?.filter((f) => f.sku == productSku.trim())[0];
            product && product.related_size_description
                ? productSizesArr.push(product)
                : null;
        });
    }

    if (type == 'flames') {
        productsArr.map((productSku) => {
            const product = items?.filter((f) => f.sku == productSku.trim())[0];
            product && product.related_flames_description
                ? productFlamesArr.push(product)
                : null;
        });
    }

    if (type == 'others') {
        productsArr.map((productSku) => {
            const product = items?.filter((f) => f.sku == productSku.trim())[0];
            product && product.related_others_description
                ? productOthersArr.push(product)
                : null;
        });
    }
    return data ? (
        items.length > 0 ? (
            <div className={'mb-8'}>
                <Disclosure as="div" key={name} defaultOpen={true}>
                    <Disclosure.Button
                        className={'text-lg lg:text-xl font-bold'}
                    >
                        {name}
                    </Disclosure.Button>
                    <Disclosure.Panel className={'pt-2'}>
                        <div className={'grid grid-cols-2 gap-4'}>
                            {/*FLAMES*/}
                            {productFlamesArr && productFlamesArr?.length > 0
                                ? productFlamesArr.map((product, index) => {
                                      return index == 6 && !moreFlames ? (
                                          type && !moreFlames ? (
                                              <button
                                                  key={index}
                                                  className={
                                                      'block bg-skin-site-darker-bg  text-center px-2 py-3 text-sm font-semibold border-transparent border-2 border-solid hover:text-black hover:border-skin-blue rounded-md '
                                                  }
                                                  onClick={() =>
                                                      setShowMoreVariants(
                                                          true,
                                                          type
                                                      )
                                                  }
                                              >
                                                  Mehr anzeigen
                                              </button>
                                          ) : null
                                      ) : index < 6 || moreFlames ? (
                                          <div key={product.sku}>
                                              {product.sku == parentSku ? (
                                                  <div key={product.sku}>
                                                      <span
                                                          className={
                                                              'block bg-skin-site-secondary-bg  text-center px-2 py-3 text-sm font-semibold border-skin-blue border-2 border-solid hover:text-black rounded-md '
                                                          }
                                                      >
                                                          <RenderDescription
                                                              attrName={name}
                                                              product={product}
                                                          />
                                                      </span>
                                                  </div>
                                              ) : (
                                                  <Link
                                                      key={product.sku}
                                                      href={
                                                          '/' +
                                                          product.url_key +
                                                          '.html'
                                                      }
                                                      passHref
                                                  >
                                                      <a
                                                          className={
                                                              'block bg-skin-site-secondary-bg  text-center px-2 py-3 text-sm font-semibold border-transparent border-2 border-solid hover:text-black hover:border-skin-blue rounded-md '
                                                          }
                                                      >
                                                          <RenderDescription
                                                              attrName={name}
                                                              product={product}
                                                          />
                                                      </a>
                                                  </Link>
                                              )}
                                          </div>
                                      ) : null;
                                  })
                                : null}
                            {/*FLAMES*/}
                            {/*SIZES*/}
                            {productSizesArr && productSizesArr?.length > 0
                                ? productSizesArr.map((product, index) => {
                                      return index == 6 && !moreSizes ? (
                                          type && !moreSizes ? (
                                              <button
                                                  key={index}
                                                  className={
                                                      'block bg-skin-site-darker-bg  text-center px-2 py-3 text-sm font-semibold border-transparent border-2 border-solid hover:text-black hover:border-skin-blue rounded-md '
                                                  }
                                                  onClick={() =>
                                                      setShowMoreVariants(
                                                          true,
                                                          type
                                                      )
                                                  }
                                              >
                                                  Mehr anzeigen
                                              </button>
                                          ) : null
                                      ) : index < 6 || moreSizes ? (
                                          <div key={product.sku}>
                                              {product.sku == parentSku ? (
                                                  <div key={product.sku}>
                                                      <span
                                                          className={
                                                              'block bg-skin-site-secondary-bg  text-center px-2 py-3 text-sm font-semibold border-skin-blue border-2 border-solid hover:text-black rounded-md '
                                                          }
                                                      >
                                                          <RenderDescription
                                                              attrName={name}
                                                              product={product}
                                                          />
                                                      </span>
                                                  </div>
                                              ) : (
                                                  <Link
                                                      key={product.sku}
                                                      href={
                                                          '/' +
                                                          product.url_key +
                                                          '.html'
                                                      }
                                                      passHref
                                                  >
                                                      <a
                                                          className={
                                                              'block bg-skin-site-secondary-bg  text-center px-2 py-3 text-sm font-semibold border-transparent border-2 border-solid hover:text-black hover:border-skin-blue rounded-md '
                                                          }
                                                      >
                                                          <RenderDescription
                                                              attrName={name}
                                                              product={product}
                                                          />
                                                      </a>
                                                  </Link>
                                              )}
                                          </div>
                                      ) : null;
                                  })
                                : null}
                            {/*SIZES*/}
                            {/*OTHERS*/}
                            {productOthersArr && productOthersArr?.length > 0
                                ? productOthersArr.map((product, index) => {
                                      return index == 6 && !moreOthers ? (
                                          type && !moreOthers ? (
                                              <button
                                                  key={index}
                                                  className={
                                                      'block bg-skin-site-darker-bg  text-center px-2 py-3 text-sm font-semibold border-transparent border-2 border-solid hover:text-black hover:border-skin-blue rounded-md '
                                                  }
                                                  onClick={() =>
                                                      setShowMoreVariants(
                                                          true,
                                                          type
                                                      )
                                                  }
                                              >
                                                  Mehr anzeigen
                                              </button>
                                          ) : null
                                      ) : index < 6 || moreOthers ? (
                                          <div key={product.sku}>
                                              {product.sku == parentSku ? (
                                                  <div key={product.sku}>
                                                      <span
                                                          className={
                                                              'block bg-skin-site-secondary-bg  text-center px-2 py-3 text-sm font-semibold border-skin-blue border-2 border-solid hover:text-black rounded-md '
                                                          }
                                                      >
                                                          <RenderDescription
                                                              attrName={name}
                                                              product={product}
                                                          />
                                                      </span>
                                                  </div>
                                              ) : (
                                                  <Link
                                                      key={product.sku}
                                                      href={
                                                          '/' +
                                                          product.url_key +
                                                          '.html'
                                                      }
                                                      passHref
                                                  >
                                                      <a
                                                          className={
                                                              'block bg-skin-site-secondary-bg  text-center px-2 py-3 text-sm font-semibold border-transparent border-2 border-solid hover:text-black hover:border-skin-blue rounded-md '
                                                          }
                                                      >
                                                          <RenderDescription
                                                              attrName={name}
                                                              product={product}
                                                          />
                                                      </a>
                                                  </Link>
                                              )}
                                          </div>
                                      ) : null;
                                  })
                                : null}
                            {/*OTHERS*/}
                        </div>
                    </Disclosure.Panel>
                </Disclosure>
            </div>
        ) : (
            <span />
        )
    ) : (
        <span />
    );
};

export default RenderSerie;
