import React from 'react';
import { bool, func } from 'prop-types';
import CloseIcon from '../../images/close.svg';

const Fullscreen = React.memo(({ isFullscreen, onClick }) => {
    return (
        isFullscreen && (
            <button
                type="button"
                className="absolute top-2 right-2 sm:top-4 sm:right-4"
                onClick={onClick}
                aria-label="Open Fullscreen"
            >
                <CloseIcon className="scale-75 sm:scale-100" />
            </button>
        )
    );
});

Fullscreen.displayName = 'Fullscreen';

Fullscreen.propTypes = {
    isFullscreen: bool.isRequired,
    onClick: func.isRequired
};

export default Fullscreen;
