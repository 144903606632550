export const checkUrlExists = (url) => {
    const request = new XMLHttpRequest();
    request.open('HEAD', url, false);
    request.send();
    if (request.readyState == 4 && request.status == 200) {
        return true;
    } else {
        return false;
    }
};
