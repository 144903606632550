import React from 'react';
import getUrlKey from '~/lib/getUrlKey';
import { initializeApollo } from '~/lib/apollo-client';
import STATIC_PRODUCTS from '~/queries/GetStaticProducts.graphql';
import STATIC_CATEGORIES from '~/queries/GetStaticCategories.graphql';
import Category from '~/components/Category';
//import CATEGORY_QUERY from '~/components/Category/Category.graphql';
// import createAppDataCache from '../lib/createAppDataCache';
// import createProductDataCache from '../lib/createProductDataCache';
import createUrlCache from '../lib/createUrlCache';
import Product from '~/components/Product';
//import getStaticAttributes from '../lib/getStaticAttributes';
import getProductData from '../lib/getProductData';
import getPageData from '../lib/getPageData';
import APP_QUERY from '~/queries/App.graphql';
//import getAppData from '~/lib/getAppData';
import resolveUrl from '../lib/resolveUrl';
import CmsPage from '../components/CmsPage';
import getCategoryData from '../lib/getCategoryData';
import ErrorPage from '../pages/errorPage';

const CONTENT_TYPE = {
    CMS_PAGE: 'CMS_PAGE',
    CATEGORY: 'CATEGORY',
    PRODUCT: 'PRODUCT'
};
export default function URLResolver(props) {
    const { type, apiData } = props;

    if (type === CONTENT_TYPE.CMS_PAGE) {
        return <CmsPage pageData={apiData} />;
    }

    if (type === CONTENT_TYPE.CATEGORY) {
        return <Category apiData={apiData} />;
    }

    if (type === CONTENT_TYPE.PRODUCT) {
        return <Product product={apiData} />;
    }

    if (type === '404' || type === '500') {
        return <ErrorPage />;
    }
}

// This function gets called at build time
export async function getStaticPaths() {
    const env = process.env.NODE_ENV;

    const disableStaticGeneration = process.env.DISABLE_STATIC_GENERATION; // Disable the static generation by setting this to TRUE

    const paths = [];

    // Prevent building the whole website in dev mode
    if (disableStaticGeneration === 'true') {
        console.log('Dev mode');
        paths.push({
            params: {
                pageUrl: ['home']
            }
        });
    } else {
        // Get the paths we want to pre-render
        const apolloClient = initializeApollo();

        // Add product paths
        let currentPage = 1;
        let totalPages = null;
        while (totalPages == null || totalPages >= currentPage) {
            const { data: productPaths } = await apolloClient.query({
                query: STATIC_PRODUCTS,
                fetchPolicy: 'network-only',
                variables: {
                    pageSize: process.env.PRODUCTS_BUILD_PAGE_SIZE,
                    currentPage: currentPage
                }
            }); // Preload Static Products Data

            productPaths?.products?.items &&
                productPaths.products.items.map(async (item) => {
                    paths.push({
                        params: {
                            pageUrl: [item.url_key + item.url_suffix]
                        }
                    });
                    await createUrlCache(
                        item.url_key + item.url_suffix,
                        'PRODUCT',
                        item
                    );
                });

            currentPage = productPaths.products.page_info.current_page + 1;
            totalPages = productPaths.products.page_info.total_pages;
        }

        // Add categories paths

        const { data: categoryPaths } = await apolloClient.query({
            query: STATIC_CATEGORIES,
            fetchPolicy: 'network-only'
        }); // Preload Static Categories Data

        await Promise.all(
            categoryPaths?.categoryList &&
                categoryPaths.categoryList[0].children.map(async (level1) => {
                    const urlPath = level1.url_path;
                    paths.push({
                        params: {
                            pageUrl: urlPath.split('/')
                        }
                    });
                    await createUrlCache(urlPath, 'CATEGORY', level1);
                    await Promise.all(
                        level1.children.map(async (level2) => {
                            const urlPath = level2.url_path;
                            paths.push({
                                params: {
                                    pageUrl: urlPath.split('/')
                                }
                            });
                            await createUrlCache(urlPath, 'CATEGORY', level2);
                            await Promise.all(
                                level2.children.map(async (level3) => {
                                    const urlPath = level3.url_path;

                                    paths.push({
                                        params: {
                                            pageUrl: urlPath.split('/')
                                        }
                                    });
                                    await createUrlCache(
                                        urlPath,
                                        'CATEGORY',
                                        level3
                                    );
                                })
                            );
                        })
                    );
                })
        );
    }

    return { paths, fallback: 'blocking' };
}

export const getStaticProps = async (props) => {
    const startTime = new Date();
    const { res, params } = props;

    const apolloClient = initializeApollo();
    //let productStaticAttributes = []; // define the static attributes variable that we can populate after
    let apiData = null;
    let productData = null;
    let categoryFilters = null;
    let categoryId = null;
    let sortFields = null;

    // Get Application data and navigation items
    //const appData = await getAppData();
    await apolloClient.query({ query: APP_QUERY }); // Preload App Data

    // We are receiving the URL as an array so we turn it into a string
    const fullUrl =
        params && params.pageUrl.join('/')
            ? params.pageUrl.join('/')
            : props.pageUrl;

    const urlKey = getUrlKey(fullUrl);

    // Resolve the URL type
    const urlData = await resolveUrl(fullUrl);

    //console.log('URL data: ', urlData);
    /** ... if not found, return 404 ... */
    if (!urlData?.urlResolver) {
        if (res) res.statusCode = 404;
        return { props: { type: '404', fullUrl } };
    }

    const { type, id } = urlData.urlResolver;
    const relativeUrl = urlData.urlResolver.relative_url;
    switch (type) {
        case CONTENT_TYPE.CMS_PAGE: {
            // Get Page data
            apiData = await getPageData({ identifier: relativeUrl });
            break;
        }
        case CONTENT_TYPE.CATEGORY: {
            // Get Category data
            apiData = await getCategoryData(relativeUrl);
            break;
        }
        case CONTENT_TYPE.PRODUCT: {
            // Get Product data
            apiData = await getProductData(relativeUrl);
            //productStaticAttributes = await getStaticAttributes(id); // Fetching static attributes from product API
            break;
        }

        default:
            break;
    }
    const endTime = new Date() - startTime;
    console.info('Page generation time: %dms', endTime);
    /** Return Props */
    return {
        props: {
            //productStaticAttributes,
            //appData,
            productData,
            apiData,
            fullUrl,
            type,
            initialApolloState: apolloClient.cache.extract(), // load cached data from queries above into the initial state of the app
            categoryFilters,
            sortFields,
            categoryId
        },
        revalidate: Number(process.env.STATIC_PROPS_REVALIDATE)
    };
};
