import React, { useEffect, useRef, useState } from 'react';
import Price from '~/components/Price';
import { toggleDrawer } from '../../store/actions/app';
import { connect } from 'react-redux';
import Breadcrumbs from '../Breadcrumbs';
import AddToCartSection from './AddToCartSection';
import ImageGallery from 'react-image-gallery';
import GeneratedAttributes from './GeneratedAttributes';
import ProductsBlock from '../ProductsBlock';
import DeliveryTime from '../DeliveryTimeApi/deliveryTime';
import AttributeLabel from '../AttributeLabel';
import Labels from '../Labels/Labels';
import { isMobile, isMobileOnly } from 'react-device-detect';
import ProductSeries from '../ProductSeries';
import DeliveryIcon1 from './icons/delivery_icon1.svg';
import DeliveryIcon2 from './icons/delivery_icon2.svg';
import { InformationCircleIcon } from '@heroicons/react/solid';
import SideInfoBox from '../SideInfoBox';
import AddToWishListButton from '../Products/AddToWishListButton';
import Link from 'next/link';
import Fullscreen from './Fullscreen';
import { NextSeo } from 'next-seo';
import { ProductJsonLd } from 'next-seo';
import RenderLampEnergyLabel from './RenderLampEnergyLabel';
import SameCategoryProducts from './SameCategoryProducts';
import GET_MHS_STOCK from '../../queries/getMhsStock.graphql';
import { useLazyQuery } from '@apollo/client';
import ManufacturerInfoSidebar from './ManufacturerInfoSidebar';

const Product = (props) => {
    const { product, toggleInfobox } = props;
    const [selectedQuantity, setSelectedQuantity] = useState(1);

    const [getMhs, { data }] = useLazyQuery(GET_MHS_STOCK, {
        variables: {
            sku: product.sku
        },
        fetchPolicy: 'network-only'
    });

    const mhs_stock = data?.products.items[0]?.mhs_stock
        ? data?.products.items[0]?.mhs_stock
        : '';

    useEffect(() => {
        if (product) {
            getMhs();
        }
    }, [product]);

    console.log('Product API data: ', product);
    // Get last visited category breadcrumbs from sessionStorage, so we can use it as product Breadcrumbs
    const categoryBreadcrumbs =
        window !== 'undefined' && sessionStorage.getItem('breadcrumb') != ''
            ? JSON.parse(sessionStorage.getItem('breadcrumb'))
            : null;
    const galleryImages = (product) => {
        const images = [];

        if (product.media_gallery.length > 0) {
            product.media_gallery
                .filter((media) => media.type === 'ProductImage')
                .map((image) =>
                    images.push({
                        original: image.url + '?width=800',
                        thumbnail: image.url + '?width=100',
                        thumbnailAlt: image.label,
                        originalAlt: image.label,
                        description: image.label
                    })
                );
            return images;
        } else {
            return [
                {
                    original:
                        '/pub/media/catalog/product/placeholder/stores/4/placeholder_funkel.jpg',
                    thumbnail:
                        '/pub/media/catalog/product/placeholder/stores/4/placeholder_funkel.jpg',
                    description: 'placeholder'
                }
            ];
        }
    };

    const openGraphImages = (product) => {
        const images = [];

        if (product.media_gallery.length > 0) {
            product.media_gallery
                .filter((media) => media.type === 'ProductImage')
                .map((image) => images.push(image.url + '?width=800'));
            return images;
        } else {
            return [
                {
                    original:
                        '/pub/media/catalog/product/placeholder/stores/4/placeholder_funkel.jpg',
                    thumbnail:
                        '/pub/media/catalog/product/placeholder/stores/4/placeholder_funkel.jpg',
                    description: 'placeholder'
                }
            ];
        }
    };

    const galleryRef = useRef();

    //Handle Breadcrumbs
    const savedParentCat =
        categoryBreadcrumbs?.[categoryBreadcrumbs?.length - 1];
    const usePreparedBreadCrumbs =
        product?.categories?.filter(
            (f) =>
                f?.url_path && f?.url_path == savedParentCat?.category_url_path
        )?.length > 0
            ? true
            : false;
    //end handle breadcrumbs
    const parentCategory = JSON.parse(
        sessionStorage.getItem('parentCategory') || 'null'
    ); //We take parent category from sessionStorage
    const manifacturerId = product?.sku?.slice(0, 6);
    return (
        <React.Fragment>
            <NextSeo
                description={product?.meta_description}
                title={product?.meta_title}
            />
            <ProductJsonLd
                productName={product.name}
                images={openGraphImages(product)}
                sku={product.sku}
                description={product.meta_description}
                color={product.color}
                offers={[
                    {
                        price: product.price_range?.minimum_price.final_price
                            .value,
                        priceCurrency: 'EUR',
                        itemCondition: 'https://schema.org/NewCondition',
                        availability: 'https://schema.org/InStock',
                        url:
                            'https://funkelhaus.de/' +
                            product.url_key +
                            '.html',
                        seller: {
                            name: 'Funkelhaus.de'
                        }
                    }
                ]}
            />
            <div className="w-full ">
                <div className="color-grid p-0">
                    {usePreparedBreadCrumbs ? (
                        <Breadcrumbs
                            breadcrumbs={categoryBreadcrumbs}
                            categoryUrlSuffix={''}
                            current={product.name}
                            clearStorage={true}
                        />
                    ) : (
                        <Breadcrumbs
                            categoryUrlSuffix={''}
                            current={product.name}
                            clearStorage={true}
                        />
                    )}
                </div>
            </div>
            <div className="color-grid pt-3 lg:pt-10">
                <div className="container-block w-full">
                    {/* Product info */}
                    <div className="mx-auto lg:grid lg:grid-cols-12 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 border-b border-solid border-skin-muted">
                        <div className="lg:col-span-7 lg:border-r lg:border-skin-muted ">
                            {/* Image gallery */}

                            <div className="md:pb-6 mx-auto relative">
                                <span className=" rellative z-10">
                                    <span
                                        className={`absolute top-0 left-0 ${
                                            galleryImages(product).length > 1 &&
                                            'lg:left-[138px]'
                                        } z-10 flex justify-start items-center`}
                                    >
                                        {product.price_range.minimum_price
                                            .discount.amount_off > 0 && (
                                            <Labels
                                                label="discount"
                                                percent_off={Math.ceil(
                                                    product.price_range
                                                        .minimum_price.discount
                                                        .percent_off
                                                )}
                                                labelClass={''}
                                            />
                                        )}

                                        {product?.product_state?.trim() ==
                                            'Y' && <Labels label="topseller" />}
                                    </span>
                                </span>
                                <ImageGallery
                                    showThumbnails={
                                        isMobileOnly ||
                                        galleryImages(product).length < 2
                                            ? false
                                            : true
                                    }
                                    showBullets={
                                        isMobileOnly &&
                                        galleryImages(product).length > 1
                                            ? true
                                            : false
                                    }
                                    ref={galleryRef}
                                    items={galleryImages(product)}
                                    thumbnailPosition={
                                        isMobile ? 'bottom' : 'left'
                                    }
                                    showPlayButton={false}
                                    showNav={false}
                                    description={false}
                                    onClick={() => {
                                        galleryRef.current.fullScreen();
                                    }}
                                    renderFullscreenButton={(
                                        onClick,
                                        isFullscreen
                                    ) => (
                                        <Fullscreen
                                            onClick={onClick}
                                            isFullscreen={isFullscreen}
                                        />
                                    )}
                                    disableThumbnailScroll
                                />
                                <AddToWishListButton
                                    product={{
                                        sku: String(product.sku)
                                    }}
                                />
                            </div>
                        </div>
                        {/* Options */}
                        <div className="mt-4 lg:mt-0  col-span-5 lg:row-span-3">
                            <div className="text-skin-muted uppercase text-sm md:text-base">
                                <AttributeLabel
                                    attributeCode="brand"
                                    values={product.brand}
                                />
                            </div>
                            <h1 className="product-page-name">
                                {product.name}
                            </h1>
                            <div className="flex items-center justify-between">
                                <RenderLampEnergyLabel
                                    energyClassProp={product?.lamp_energy_class}
                                    sku={product?.sku}
                                />
                                <div className="py-2 md:pb-4 md:pt-0">
                                    <Price
                                        priceRange={product.price_range}
                                        priceClass="flex flex-col items-end text-4xl font-bold text-skin-gray"
                                        discountClass="text-base flex text-skin-muted font-normal leading-6 mt-[1px] line-through decoration-[#e3001b]"
                                        amountOffClass="mt-1 text-sm flex font-normal sm:leading-[0.5rem]"
                                        showSaved={true}
                                        priceTag={
                                            product?.price_tag?.includes('U1')
                                                ? 'UVP* '
                                                : null
                                        }
                                    />
                                    <div className="mt-2 text-right text-xs text-skin-muted">
                                        Inkl. MwSt. zzgl.{' '}
                                        <Link
                                            passHref
                                            href="/lieferung-zahlung"
                                        >
                                            <a className="underline">Versand</a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <ProductSeries
                                parentSku={product.sku}
                                data={{
                                    related_size: product.related_size,
                                    related_size_description:
                                        product.related_size_description,
                                    related_color: product.related_color,
                                    related_color_description:
                                        product.related_color_description,
                                    related_flames: product.related_flames,
                                    related_flames_description:
                                        product.related_flames_description,
                                    related_others: product.related_others,
                                    related_others_description:
                                        product.related_others_description
                                }}
                            />
                            {mhs_stock ? (
                                <div className="border-t border-solid border-skin-muted py-4 text-base lg:text-lg font-semibold leading-3">
                                    <DeliveryTime
                                        sku={product.sku}
                                        deliveryType={product.delivery_type}
                                        deliveryTime={product.delivery_time}
                                        selectedQuantity={Number(
                                            selectedQuantity
                                        )}
                                        rootClass={'text-sm'}
                                        mhsStock={mhs_stock}
                                        productState={product.product_state}
                                    />

                                    <span className="font-normal text-sm">
                                        {product.disposal_small ? (
                                            <span
                                                onClick={() =>
                                                    toggleInfobox('infobox')
                                                }
                                                className="w-fit block text-sm underline decoration-solid cursor-pointer"
                                            >
                                                Hinweise zur Altgeräteentsorgung{' '}
                                                <InformationCircleIcon className="w-3 h-3 inline" />
                                            </span>
                                        ) : null}
                                    </span>
                                </div>
                            ) : null}
                            <div className="py-4">
                                <AddToCartSection
                                    product={{
                                        ...product,
                                        mhs_stock: mhs_stock
                                    }}
                                    sku={product.sku}
                                    getQuantity={setSelectedQuantity}
                                />
                            </div>
                            <div className="py-4 border-t border-solid border-skin-muted">
                                <span className="flex items-center mb-2 text-sm lg:text-base">
                                    <span className="flex justify-center w-8 mr-2">
                                        <DeliveryIcon1
                                            className={'w-6 h-7 lg:w-7 lg:h-8'}
                                        />
                                    </span>{' '}
                                    30 Tage Rückgaberecht
                                </span>
                                <span className="flex items-center mb-2 text-sm lg:text-base">
                                    <span className="flex justify-center w-8 mr-2">
                                        <DeliveryIcon2
                                            className={'w-7 h-5 lg:w-8 lg:h-6'}
                                        />
                                    </span>{' '}
                                    Kostenloser Versand ab 50 Euro
                                </span>
                                {/* <span className="flex items-center mb-2 text-sm lg:text-base">
                                    <span className="flex justify-center w-8 mr-2">
                                        <DeliveryIcon3
                                            className={'w-5 h-5 lg:w-6 lg:h-6'}
                                        />
                                    </span>{' '}
                                    Kauf auf Rechnung möglich
                                </span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                {product.dimmable_text ||
                product.dimm_type ||
                product.lightcolor ||
                product.incl_lamp_fkh ||
                product.bulb_changeable_fkh ||
                product.light_flux ? (
                    <div className="py-7">
                        <div className="content-container">
                            <h2 className="mb-8">Produktdetails</h2>

                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8 pt-2">
                                <GeneratedAttributes product={product} />
                            </div>
                        </div>
                    </div>
                ) : null}

                {product.related_products &&
                product.related_products.length > 0 ? (
                    <div className="color-grid">
                        <div className="content-container">
                            <ProductsBlock
                                products={product.related_products}
                                title="Produkte aus der gleichen Serie"
                            />
                        </div>
                    </div>
                ) : null}
                {product.upsell_products &&
                product.upsell_products.length > 0 ? (
                    <div className="color-grid">
                        <div className="content-container">
                            <ProductsBlock
                                products={product.upsell_products}
                                title="Dies könnte Ihnen auch gefallen"
                            />
                        </div>
                    </div>
                ) : null}
                <div className="mt-6 content-container">
                    <button
                        className="flex items-center"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleInfobox('manufacturerInfo');
                        }}
                    >
                        <InformationCircleIcon className="mr-2 h-5 w-5" />
                        Details zur Produktsicherheit
                    </button>
                </div>
            </div>

            {product.disposal_small ? (
                <SideInfoBox headline={'Altgeräteentsorgung'}>
                    <p>
                        Wenn Sie alte und nicht mehr funktionstüchtige
                        Elektrogeräte entsorgen möchten, haben Sie
                        unterschiedliche Möglichkeiten, um dies möglichst
                        nachhaltig zu tun. Zum einen können Sie die alten Stücke
                        in haushaltsüblichen Mengen bei einem Wertstoff- oder
                        Recyclinghof in Ihrer Nähe kostenfrei abgeben, wo alle
                        verwertbaren Teile dem Recycling-Kreislauf zugeführt
                        werden. Zum anderen bieten auch die bundesweit
                        vertretenen Annahmestellen von Interseroh eine
                        kostenlose Rücknahme an.{' '}
                        <a
                            href="https://www.interseroh.de/weee-annahmestellen"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <strong>Hier</strong>
                        </a>{' '}
                        finden Sie eine Interseroh-Stelle ganz in Ihrer Nähe
                        sowie weitere Informationen zu den
                        Rücknahmevoraussetzungen. Die Rückgabe bei Interseroh im
                        Überblick:{' '}
                    </p>
                    <ul className="styled-list">
                        <li>
                            kleine Altgeräte mit einer Kantenlänge bis 25 cm
                            können an den Sammelstellen in haushaltsüblichen
                            Mengen kostenfrei abgegeben werden.
                        </li>
                        <li>
                            bei einer Kantenlänge über 25 cm nimmt Interseroh
                            das Altgerät dann zurück, wenn Sie bei uns ein
                            funktionsgleiches Neugerät erworben haben. Zeigen
                            Sie zum Nachweis einfach den Kaufbeleg bei Ihrer
                            Interseroh- Sammelstelle vor.
                        </li>
                    </ul>
                    <p>
                        Bei Selbstabholung Ihres neuen Gerätes in unseren
                        Filialen nehmen wir Ihr Altgerät ebenfalls kostenlos
                        zurück und sorgen für eine umweltfreundliche Entsorgung.{' '}
                        <a
                            href="https://www.interseroh.de/weee-annahmestellen"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <strong>Hier</strong>
                        </a>{' '}
                        finden Sie eine Filiale ganz in Ihrer Nähe.
                    </p>
                </SideInfoBox>
            ) : null}
            <ManufacturerInfoSidebar id={manifacturerId} />
            <section className="content-container w-full">
                {product?.categories ? (
                    <SameCategoryProducts
                        productCategories={product.categories}
                        productSku={product.sku}
                        parentCategory={parentCategory}
                    />
                ) : null}
            </section>
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleInfobox: (payload) => dispatch(toggleDrawer(payload))
    };
};

export default connect(null, mapDispatchToProps)(Product);
