import React, { useEffect, useRef, useState } from 'react';
import Breadcrumbs from '../Breadcrumbs';
import FilterSidebar from '../FilterSidebar';
import Sort from '../Sort';
import { useDispatch } from 'react-redux';
import { toggleDrawer } from '../../store/actions/app';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Slider from 'react-slick';
import NoProducts from './NoProducs';
import EmptyCategory from './EmptyCategory';
import {
    convertFiltersToObjForGraphql,
    returnSortType
} from '../../util/urlManipulatingFunctions';
import { useFindlogic } from '../../talons/Findlogic/useFindlogic';
import { Disclosure } from '@headlessui/react';
import FiltersCurrents from '../FilterSidebar/filtersCurrents';
import FilterOptions from '../FilterSidebar/filterOptions';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    AdjustmentsIcon
} from '@heroicons/react/solid';
import ProductCardFindologic from '../ProductCard/ProductCardFindologic';
import Pagination from '../Pagination';
import {
    categoryLoadingContentTemplate,
    filtersBoxLoadingContentTemplate,
    filterHeadlineTemplate
} from '../LoadingContent/loadingTemplates';
import SeoText from '../SeoText';
import CmsBlock from '../CmsBlock/cmsBlock';
import Sticky from 'react-sticky-el';
import listFindlogicFilters from '../../util/listWithpossibleFindlogicFilters';
import StickyBox from 'react-sticky-box';
import shuffleArray from '../../util/shuffleArray';
import GET_CATEGORY_BANNERS from '../../queries/getCategoryBanners.graphql';
import { useQuery } from '@apollo/client';
import CategoryBanner from './CategoryBanner';

const CategoryContent = ({ category, categoryUrlSuffix }) => {
    const router = useRouter();
    const pageSize = 34;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [initLoading, setInitLoading] = useState(true);
    const [sortLoading, setSortLoading] = useState(false);
    const toggleFiltersSidebar = () => {
        dispatch(toggleDrawer('filter'));
    };
    const routerQuery = router.query;

    const {
        data: dataCategoryBanners,
        loading: loadingCategoryBanners,
        error: errorCategoryBanners
    } = useQuery(GET_CATEGORY_BANNERS, {
        variables: {
            id: category.id
        }
    });

    // construct array for active filters number
    const handledArr = [];
    Object.keys(routerQuery).map((property) => {
        if (
            (listFindlogicFilters.includes(property) ||
                property.includes('slider')) &&
            property !== 'query' &&
            property !== 'page' &&
            property !== 'purge'
        ) {
            handledArr.push({
                filterKey: property,
                value: routerQuery[property]
            });
        }
    });

    const convertedFilters = convertFiltersToObjForGraphql(router, undefined);
    const sortValue = returnSortType(router, true)
        ? returnSortType(router, true)
        : null;
    const fetchMoreMode = false; //Turn on or turn off the pagination mode
    const defaultSorting = {};
    sortFields ? (defaultSorting[sortFields.default] = 'ASC') : undefined;
    const [results, setResults] = useState();
    const scrollRef = useRef(null);
    const mobileScrollRef = useRef(null);
    const totalPages = results
        ? Math.ceil(results?.result?.metadata?.totalResults / pageSize)
        : 1;
    const currentPage = !router.query.page ? 1 : Number(router.query.page);
    const pageInfo = {
        total_pages: totalPages,
        current_page: currentPage
    };
    const categoryName = category && category.name ? category.name : null;
    const { getResultsCategory } = useFindlogic({
        setLoading,
        setInitLoading,
        setSortLoading,
        categoryName
    });

    useEffect(() => {
        router.isReady
            ? getResultsCategory(
                  setResults,
                  convertedFilters,
                  sortValue,
                  Number(pageInfo.current_page),
                  categoryName
              )
            : null;
    }, [router.query]);
    const products = results ? results.result.items : null;
    const filters = results ? results.result.filters.main : null;
    const handledFilters = [];
    const sortFields = {
        defaultValue: 'salesfrequency DESC',
        options: [
            { label: 'Topseller', value: 'salesfrequency' },
            { label: 'Preis', value: 'price' }
            // { label: 'Neuheiten', value: 'dateadded' }
        ]
    };
    const productUrlSuffix = null;
    filters &&
        Array.isArray(filters) &&
        filters.map((filter) => {
            handledFilters.push({
                amshopby_filter_data: null,
                attribute_code: filter.name,
                label: filter.displayName,
                options: filter.values,
                type: filter.type
            });
        });

    const filtersCategoryBox = (mobile) => (
        <StickyBox
            className={!mobile ? 'hidden lg:block' : ''}
            offsetTop={8}
            offsetBottom={8}
        >
            <div className={'relative'}>
                {filters ? (
                    <FiltersCurrents
                        filters={filters}
                        loading={loading}
                        scrollRef={scrollRef}
                        mobileScrollRef={mobileScrollRef}
                    />
                ) : null}
                {handledFilters &&
                    handledFilters.map((section) => (
                        <div
                            key={section.attribute_code}
                            className={mobile ? 'px-4' : ''}
                        >
                            <Disclosure
                                as="div"
                                className="lg:pr-4"
                                defaultOpen={true}
                            >
                                {({ open }) => (
                                    <>
                                        <h3 className="flow-root">
                                            <Disclosure.Button className="border-solid border-t border-skin-primary py-2 bg-white w-full flex items-center justify-between text-left text-gray-400 hover:text-gray-500">
                                                <span className="font-medium text-gray-900 leading-5">
                                                    {section.label}
                                                </span>
                                                <span className="ml-6 flex items-center">
                                                    {open ? (
                                                        <ChevronUpIcon className="h-7 w-7 text-skin-base" />
                                                    ) : (
                                                        <ChevronDownIcon className="h-7 w-7 text-skin-base" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </h3>
                                        <Disclosure.Panel className="pb-4 pr-2 block lg:hidden">
                                            <FilterOptions
                                                options={section.options}
                                                attributeCode={
                                                    section.attribute_code
                                                }
                                                isColorType={
                                                    section.type == 'color'
                                                        ? true
                                                        : false
                                                }
                                                loading={loading}
                                                display_mode_label={
                                                    section.type ==
                                                    'range-slider'
                                                        ? 'Slider'
                                                        : 'Labels'
                                                }
                                                searchFilters={true}
                                                rangeSliderButtonMode={true}
                                                mobileScrollRef={
                                                    mobileScrollRef
                                                }
                                            />
                                        </Disclosure.Panel>
                                        <Disclosure.Panel className="pb-4 pr-2 hidden lg:block">
                                            <FilterOptions
                                                options={section.options}
                                                attributeCode={
                                                    section.attribute_code
                                                }
                                                isColorType={
                                                    section.type == 'color'
                                                        ? true
                                                        : false
                                                }
                                                loading={loading}
                                                display_mode_label={
                                                    section.type ==
                                                    'range-slider'
                                                        ? 'Slider'
                                                        : 'Labels'
                                                }
                                                searchFilters={true}
                                                scrollRef={scrollRef}
                                            />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>
                    ))}
                <div className="bg-white border-t border-skin-primary border-solid py-5 px-4 sticky bottom-0 left-0 block lg:hidden">
                    <button
                        className="styled-button w-full"
                        onClick={() => toggleFiltersSidebar()}
                        // disabled={
                        //     (Object.keys(routerQuery).length === 0 &&
                        //         routerQuery.constructor === Object) ||
                        //     (Array.isArray(routerQuery) &&
                        //         routerQuery.length == 0)
                        // }
                    >
                        Anwenden
                    </button>
                </div>
            </div>
        </StickyBox>
    );

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow:
            category?.children &&
            Array.isArray(category.children) &&
            category?.children?.length < 6
                ? category?.children?.length
                : 6,
        slidesToScroll:
            category?.children &&
            Array.isArray(category.children) &&
            category?.children?.length < 6
                ? category?.children?.length
                : 6,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow:
                        category?.children &&
                        Array.isArray(category.children) &&
                        category?.children?.length < 4
                            ? category?.children?.length
                            : 4,
                    slidesToScroll:
                        category?.children &&
                        Array.isArray(category.children) &&
                        category?.children?.length < 4
                            ? category?.children?.length
                            : 4
                }
            }
        ]
    };

    //Setting the category Id in the local storage so we can use it in product breadcrumb
    if (typeof window !== 'undefined') {
        category.breadcrumbs
            ? sessionStorage.setItem(
                  'breadcrumb',
                  JSON.stringify([
                      ...category.breadcrumbs,
                      {
                          category_name: category.name,
                          category_url_path: category.url_path,
                          id: category.id,
                          category_level: 100
                      }
                  ])
              )
            : sessionStorage.setItem(
                  'breadcrumb',
                  JSON.stringify([
                      {
                          category_name: category.name,
                          category_url_path: category.url_path,
                          id: category.id,
                          category_level: 100
                      }
                  ])
              );

        sessionStorage.setItem(
            'parentCategory',
            JSON.stringify({
                category_url_path: category.url_path,
                id: category.id
            })
        );
    }
    const isKitchen =
        category?.url_path?.split('/')?.length > 0
            ? category?.url_path?.split('/')[0] == 'kuechen'
                ? true
                : false
            : false;

    const categoryBannersCopy =
        dataCategoryBanners &&
        dataCategoryBanners.categoryBanners &&
        dataCategoryBanners.categoryBanners.allBanners
            ? dataCategoryBanners.categoryBanners.allBanners.slice()
            : null;
    const shuffledCategoryBanners = shuffleArray(categoryBannersCopy);

    // duplicate list items if length is less than 4
    shuffledCategoryBanners &&
        shuffledCategoryBanners.length < 4 &&
        shuffledCategoryBanners.push.apply(
            shuffledCategoryBanners,
            shuffledCategoryBanners
        );

    return (
        <div className="w-full " id="categoryContentScrollCheck">
            <section className="color-grid p-0">
                <Breadcrumbs current={'Suche'} />
            </section>
            {category.display_mode != 'PAGE' ? (
                <div className=" pt-12">
                    {category.display_mode != 'PAGE' ? (
                        <div className="w-full container-block">
                            <h1 className="text-lg font-bold tracking-tight lg:hidden">
                                {category.name}
                            </h1>
                            <div className="hidden lg:block">
                                <Slider
                                    {...settings}
                                    className={`${
                                        category.children.length < 4
                                            ? 'category-slider'
                                            : ''
                                    }`}
                                >
                                    {category.children
                                        .sort((a, b) =>
                                            a.position < b.position ? -1 : 1
                                        )
                                        .map((category) => (
                                            <div
                                                key={category.id}
                                                className="py-3"
                                            >
                                                <a
                                                    href={
                                                        '/' + category.url_path
                                                    }
                                                    className="relative font-normal content-center text-center text-xl p-0"
                                                >
                                                    <span className="bg-white py-2 w-full block">
                                                        <Image
                                                            // layout="fill"
                                                            src={
                                                                category.image &&
                                                                !category.image.includes(
                                                                    'productno_selection'
                                                                )
                                                                    ? category.image.replace(
                                                                          'https://funkelhaus-next.habitatmade.com/',
                                                                          '/'
                                                                      )
                                                                    : 'https://checkout.funkelhaus.de/pub/media/catalog/product/placeholder/stores/4/placeholder_funkel.jpg'
                                                                //TODO check why we have to replace the string
                                                            }
                                                            alt={category.name}
                                                            width={179}
                                                            height={89}
                                                            className="object-contain"
                                                        />
                                                    </span>
                                                    <span className="flex grow items-center bg-none justify-center text-base">
                                                        {category.name}{' '}
                                                        <ChevronRightIcon className="ml-3 h-6 w-6 cursor-pointer" />
                                                    </span>
                                                </a>
                                            </div>
                                        ))}
                                </Slider>
                            </div>
                            {category.children.length > 0 && (
                                <ul
                                    role="list"
                                    className="w-full mb-6 grid grid-cols-1 gap-x-6 lg:hidden"
                                >
                                    {category.children
                                        .sort((a, b) =>
                                            a.position < b.position ? -1 : 1
                                        )
                                        .map((category) => {
                                            const categoryThumbnail =
                                                category.image
                                                    ? '/pub' +
                                                      new URL(category.image)
                                                          ?.pathname
                                                    : '/pub/media/catalog/product/placeholder/stores/4/placeholder_funkel.jpg';
                                            return (
                                                <li
                                                    key={category.id}
                                                    className="py-3 border-solid border-b border-skin-primary last:border-0 lg:py-3 lg:border-0"
                                                >
                                                    <a
                                                        href={
                                                            '/' +
                                                            category.url_path
                                                        }
                                                        className="relative flex justify-between flex-row font-normal text-base lg:px-2 lg:flex-col lg:text-xl lg:p-0"
                                                    >
                                                        <span className="flex items-center space-between bg-white">
                                                            <div className="relative min-w-[3rem] min-h-[3rem] mr-2">
                                                                <Image
                                                                    layout="fill"
                                                                    src={
                                                                        categoryThumbnail
                                                                    }
                                                                    alt={
                                                                        category.name
                                                                    }
                                                                    className="object-contain"
                                                                />
                                                            </div>
                                                            {category.name}
                                                        </span>
                                                        <span className="px-3 py-2 flex justify-between items-center bg-white lg:bg-none lg:justify-center lg:px-0">
                                                            <ChevronRightIcon className="h-6 w-6 cursor-pointer" />
                                                        </span>
                                                    </a>
                                                </li>
                                            );
                                        })}
                                </ul>
                            )}
                        </div>
                    ) : null}

                    <div className="w-full ">
                        <div className="w-full container-block p-0 lg:px-5 xl:px-12">
                            {handledFilters ? (
                                <FilterSidebar childrenMode={true}>
                                    {filtersCategoryBox(true)}
                                </FilterSidebar>
                            ) : null}
                            {handledFilters ? (
                                loading &&
                                !sortLoading &&
                                window.innerWidth < 1024 ? (
                                    filterHeadlineTemplate
                                ) : (
                                    <div className="relative flex items-baseline justify-between lg:border-solid lg:border-b lg:border-skin-primary flex-wrap lg:flex-nowrap lg:mb-8">
                                        <div className="w-full text-center lg:text-left">
                                            <h1
                                                ref={scrollRef}
                                                className="hidden lg:block mb-0"
                                            >
                                                {category.name}
                                            </h1>
                                            {category.display_mode != 'PAGE' &&
                                            results?.result?.metadata
                                                ?.totalResults ? (
                                                <h2
                                                    ref={mobileScrollRef}
                                                    className="text-sm font-semibold my-4 lg:hidden mobileScrollTarget"
                                                >
                                                    {results?.result?.metadata
                                                        ?.totalResults ? (
                                                        <span>
                                                            {
                                                                results?.result
                                                                    ?.metadata
                                                                    ?.totalResults
                                                            }
                                                        </span>
                                                    ) : null}{' '}
                                                    Artikel gefunden
                                                </h2>
                                            ) : null}
                                        </div>
                                        {category.display_mode != 'PAGE' ? (
                                            Object.keys(convertedFilters)
                                                .length == 0 &&
                                            convertedFilters.constructor ===
                                                Object &&
                                            products &&
                                            Array.isArray(products) &&
                                            products.length == 0 &&
                                            !loading ? null : (
                                                <div className="relative w-full flex items-baseline justify-between flex-wrap lg:flex-nowrap lg:w-auto lg:pb-3">
                                                    <Sort
                                                        sortFields={sortFields}
                                                        className={
                                                            'hidden lg:block'
                                                        }
                                                        setSortLoading={
                                                            setSortLoading
                                                        }
                                                    />
                                                    <Sticky
                                                        className="w-full z-10 filtersSticky"
                                                        topOffset={-48}
                                                    >
                                                        <div className="w-full flex lg:hidden bg-white border-b border-solid border-skin-primary lg:border-b-0">
                                                            <button
                                                                type="button"
                                                                className="flex flex-row-reverse justify-center items-center p-2 border border-solid border-skin-primary border-b-0 border-l-0 w-1/2"
                                                                onClick={() =>
                                                                    toggleFiltersSidebar()
                                                                }
                                                            >
                                                                <span
                                                                    className={`text-sm font-semibold ${
                                                                        handledArr.length >
                                                                        0
                                                                            ? 'text-skin-bolder-hover'
                                                                            : ''
                                                                    } lg:hidden`}
                                                                >
                                                                    {`Filtern ${
                                                                        handledArr.length >
                                                                        0
                                                                            ? `(${handledArr.length})`
                                                                            : ''
                                                                    }`}
                                                                </span>
                                                                <AdjustmentsIcon
                                                                    className={`w-6 mr-3 ${
                                                                        handledArr.length >
                                                                        0
                                                                            ? 'text-skin-bolder-hover'
                                                                            : ''
                                                                    }`}
                                                                    aria-hidden="true"
                                                                />
                                                            </button>
                                                            <Sort
                                                                sortFields={
                                                                    sortFields
                                                                }
                                                                className="w-1/2 flex-grow"
                                                                setSortLoading={
                                                                    setSortLoading
                                                                }
                                                            />
                                                        </div>
                                                    </Sticky>
                                                </div>
                                            )
                                        ) : null}
                                    </div>
                                )
                            ) : null}
                        </div>
                        {Object.keys(convertedFilters).length == 0 &&
                        convertedFilters.constructor === Object &&
                        products &&
                        Array.isArray(products) &&
                        products.length == 0 &&
                        !loading &&
                        category.display_mode != 'PAGE' ? (
                            <div className="py-20 bg-white">
                                <div className="w-full container-block">
                                    <EmptyCategory />
                                </div>
                            </div>
                        ) : category.display_mode != 'PAGE' ? (
                            <div className="w-full container-block p-0 lg:px-5 xl:px-12">
                                <div
                                    className={
                                        'grid items-start grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10 grid-flow-col'
                                    }
                                >
                                    {initLoading
                                        ? filtersBoxLoadingContentTemplate
                                        : filtersCategoryBox(false)}
                                    {Object.keys(convertedFilters).length > 0 &&
                                    convertedFilters.constructor === Object &&
                                    products &&
                                    Array.isArray(products) &&
                                    products.length == 0 ? (
                                        <div className="col-span-3 mt-12">
                                            <NoProducts
                                                isSearchFilter={false}
                                            />
                                        </div>
                                    ) : products &&
                                      Array.isArray(products) &&
                                      products.length > 0 ? (
                                        <div className="lg:col-span-3">
                                            <div
                                                className={
                                                    'mb-6 grid grid-cols-2 gap-y-0 gap-x-0 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8 xl:gap-y-8 lg:gap-x-2 lg:gap-y-2 mobile-borders-styles'
                                                }
                                            >
                                                {loading
                                                    ? categoryLoadingContentTemplate
                                                    : products.map(
                                                          (product, index) => {
                                                              let banner;
                                                              if (
                                                                  (index == 3 ||
                                                                      index ==
                                                                          7 ||
                                                                      index ==
                                                                          16 ||
                                                                      index ==
                                                                          26) &&
                                                                  shuffledCategoryBanners
                                                              ) {
                                                                  banner =
                                                                      shuffledCategoryBanners[0];
                                                                  shuffledCategoryBanners?.shift();
                                                              }

                                                              return (index ==
                                                                  3 ||
                                                                  index == 7 ||
                                                                  index == 16 ||
                                                                  index ==
                                                                      26) &&
                                                                  banner ? (
                                                                  <>
                                                                      <CategoryBanner
                                                                          key={
                                                                              index
                                                                          }
                                                                          banner={
                                                                              banner
                                                                          }
                                                                      />
                                                                      <ProductCardFindologic
                                                                          key={
                                                                              product.id
                                                                          }
                                                                          product={
                                                                              product
                                                                          }
                                                                          productUrlSuffix={
                                                                              productUrlSuffix
                                                                          }
                                                                          hideNoStockInfo={
                                                                              true
                                                                          }
                                                                      />
                                                                  </>
                                                              ) : index == 6 &&
                                                                isKitchen ? (
                                                                  [
                                                                      <div
                                                                          className="col-span-2 lg:col-span-3"
                                                                          key={Math.random()}
                                                                      >
                                                                          {isKitchen ? (
                                                                              <CmsBlock
                                                                                  identifiers={[
                                                                                      'kitchen-consultations-block'
                                                                                  ]}
                                                                              />
                                                                          ) : null}
                                                                      </div>,
                                                                      <div
                                                                          key={Math.random()}
                                                                          className={
                                                                              'hidden relative cursor-pointer border border-solid border-product-card'
                                                                          }
                                                                      />,
                                                                      <ProductCardFindologic
                                                                          key={
                                                                              product.id
                                                                          }
                                                                          product={
                                                                              product
                                                                          }
                                                                          productUrlSuffix={
                                                                              productUrlSuffix
                                                                          }
                                                                          hideNoStockInfo={
                                                                              true
                                                                          }
                                                                      />
                                                                  ]
                                                              ) : (
                                                                  <ProductCardFindologic
                                                                      key={
                                                                          product.id
                                                                      }
                                                                      product={
                                                                          product
                                                                      }
                                                                      productUrlSuffix={
                                                                          productUrlSuffix
                                                                      }
                                                                      hideNoStockInfo={
                                                                          true
                                                                      }
                                                                  />
                                                              );
                                                          }
                                                      )}
                                            </div>

                                            {pageInfo ? (
                                                <div className="px-4">
                                                    <Pagination
                                                        pageInfo={pageInfo}
                                                        fetchMoreMode={false}
                                                        loading={loading}
                                                        scrollRef={scrollRef}
                                                        mobileScrollRef={
                                                            mobileScrollRef
                                                        }
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : loading ? (
                                        <div className="lg:col-span-3">
                                            <div
                                                className={
                                                    'mb-6 grid grid-cols-2 gap-y-2 gap-x-2 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8 xl:gap-y-8 xs:gap-x-2 xs:gap-y-2'
                                                }
                                            >
                                                {categoryLoadingContentTemplate}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {category.category_seo ? (
                        <SeoText>
                            <div className="content-container">
                                <div className="ceo-text">
                                    {category.category_seo_name ? (
                                        <h2>{category.category_seo_name}</h2>
                                    ) : null}
                                </div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: category.category_seo
                                    }}
                                />
                            </div>
                        </SeoText>
                    ) : null}
                </div>
            ) : category &&
              category.cms_block &&
              category.cms_block.identifier &&
              (category.display_mode == 'PRODUCTS_AND_PAGE' ||
                  category.display_mode == 'PAGE') ? (
                <div className="mb-4">
                    <CmsBlock identifiers={category.cms_block.identifier} />
                </div>
            ) : null}
        </div>
    );
};

export default CategoryContent;
