'use client';
import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { toggleDrawer } from '../../store/actions/app';
import { useDispatch, useSelector } from 'react-redux';
import { XIcon } from '@heroicons/react/outline';
import GET_MANUFACTURER_DATA from '../../queries/getManifacturerData.graphql';
import LoadingIndicators from '../LoadingIndicators';
import { useQuery } from '@apollo/client';

// interface ManufacturerData {
//     name: string;
//     id: string;
//     manufacturer_id: string;
//     description: string;
//     street: string;
//     street_number: string;
//     postcode: string;
//     city: string;
//     country: string;
//     contacts: string;
// }

const ManufacturerInfoSidebar = (props: { id: string }) => {
    const { id } = props;
    const dispatch = useDispatch();
    const app = useSelector((state: any) => state.app);
    const drawer = app.drawer;
    const isOpen = drawer == 'manufacturerInfo';
    const toggleInfobox = (payload: string) => dispatch(toggleDrawer(payload));

    const { data, loading, error } = useQuery(GET_MANUFACTURER_DATA, {
        variables: { id: id },
        skip: !isOpen
    });

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-30 mt-[57px] overflow-hidden lg:mt-0"
                onClose={() => {
                    toggleInfobox('manufacturerInfo');
                }}
            >
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div
                        className={`fixed inset-y-0 right-0 mt-[57px] flex w-full max-w-xl lg:mt-0`}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="relative w-full">
                                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                    <div className="py-6">
                                        <div className="flex items-center justify-between px-4">
                                            <Dialog.Title className="mb-0 text-lg font-semibold lg:text-3xl"></Dialog.Title>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    className="rounded-full border-2 border-solid border-black p-1 text-black"
                                                    onClick={() =>
                                                        toggleInfobox(
                                                            'manufacturerInfo'
                                                        )
                                                    }
                                                >
                                                    <span className="sr-only">
                                                        Close panel
                                                    </span>

                                                    <XIcon
                                                        className="h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="px-4">
                                        {loading ? (
                                            <LoadingIndicators />
                                        ) : error || !data ? (
                                            <div>
                                                Die Angaben zum Hersteller sind
                                                noch nicht gepflegt oder es ist
                                                ein technischer Fehler
                                                aufgetreten. Wir pflegen die
                                                Angaben zur Produktsicherheit
                                                schnellstmöglich nach bzw.
                                                beheben den technischen Fehler.
                                            </div>
                                        ) : (
                                            <div className="flex flex-col">
                                                <h3 className="mb-2">
                                                    Verantwortliche Person für
                                                    die EU
                                                </h3>
                                                <span className="mb-2">
                                                    Verantwortlich für dieses
                                                    Produkt ist der in der EU
                                                    ansässige Wirtschaftsakteur:
                                                </span>
                                                {data?.name && (
                                                    <h3>{data?.name}</h3>
                                                )}
                                                {data?.street &&
                                                    data?.street_number &&
                                                    data?.postcode &&
                                                    data?.city &&
                                                    data?.country && (
                                                        <span>
                                                            {data?.street}{' '}
                                                            {
                                                                data?.street_number
                                                            }
                                                            , {data?.postcode}{' '}
                                                            {data?.city},{' '}
                                                            {data?.country}
                                                        </span>
                                                    )}
                                                {data?.contacts && (
                                                    <span>
                                                        {data?.contacts}
                                                    </span>
                                                )}
                                                <span className="mt-2">
                                                    Sie finden den für das
                                                    Produkt verantwortlichen
                                                    Wirtschaftsakteur auch auf
                                                    dem jeweiligen Produkt bzw.
                                                    der Verpackung oder in einer
                                                    dem Produkt beigefügten
                                                    Unterlage.
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default ManufacturerInfoSidebar;
