import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

const CategoryBanner = ({
    banner
}: {
    banner: { url: string; banner_image: string; alt_tag: string };
}) => {
    return (
        <div className="relative cursor-pointer border border-solid border-product-card">
            <Link href={banner.url}>
                <a className="relative h-full w-full block">
                    <Image
                        src={
                            '/pub/media/categorybanners/image' +
                            banner.banner_image
                        }
                        layout="fill"
                        objectFit="contain"
                        className="cursor-pointer hover:opacity-75"
                        alt={banner.alt_tag}
                    />
                </a>
            </Link>
        </div>
    );
};

export default CategoryBanner;
