import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { toggleDrawer } from '../../store/actions/app';
import { connect } from 'react-redux';
import { XIcon } from '@heroicons/react/outline';

interface SideInfoBox {
    drawer: string;
    toggleInfobox: Function;
    headline: String;
    children: object;
}

const SideInfoBox = (props: SideInfoBox) => {
    const { drawer, toggleInfobox, headline, children } = props;
    const isOpen = drawer == 'infobox';

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 overflow-hidden z-30"
                onClose={() => {
                    toggleInfobox('infobox');
                }}
            >
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-y-0 right-0 flex w-full max-w-xl">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-full relative">
                                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                    <div className="py-6">
                                        <div className="flex items-center justify-between px-4 pb-6">
                                            <Dialog.Title className="text-lg lg:text-3xl font-semibold mb-0">
                                                {headline}
                                            </Dialog.Title>
                                            <div className="ml-3 h-7 flex items-center">
                                                <button
                                                    type="button"
                                                    className="p-1 text-black border-solid border-2 border-black rounded-full"
                                                    onClick={() =>
                                                        toggleInfobox('infobox')
                                                    }
                                                >
                                                    <span className="sr-only">
                                                        Close panel
                                                    </span>

                                                    <XIcon
                                                        className="h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="px-4">{children}</div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

const mapStateToProps = (state: { app: { drawer: string } }) => {
    const {
        app: { drawer }
    } = state;
    return {
        drawer
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        toggleInfobox: (payload: string) => dispatch(toggleDrawer(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideInfoBox);
