import React from 'react';
import { Disclosure } from '@headlessui/react';
import RenderSerieColor from './renderSerieColor';

const RenderSerieColors = (props) => {
    const { data, parentSku } = props;

    return <div className={'mb-4'} >
        <Disclosure as="div" defaultOpen={open}>
            <Disclosure.Button
                className={'text-lg lg:text-xl font-bold'}
            >
                {"Farbe: "}{<span className='text-base font-normal'>: {data?.related_color_description}</span>}
            </Disclosure.Button>
            <div className={"flex justify-start mr-2 mb-2 flex-wrap"}>
                {data?.related_color?.split(",")?.map(product => {
                    return (
                        <RenderSerieColor key={product} productSku={product} parentSku={parentSku} />
                    )
                })}
            </div>
        </Disclosure>
    </div >
};

export default RenderSerieColors;
